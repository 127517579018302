import ReactMarkdown from "react-markdown";
import rehypeSanitize from "rehype-sanitize";

interface IParsedMessageProps {
    content: string;
}

const ParsedMessage: React.FC<IParsedMessageProps> = ({
    content
}) => {
    return (
        <div className="flex flex-col gap--xs">
            <ReactMarkdown rehypePlugins={[rehypeSanitize]}>{content}</ReactMarkdown>
        </div>
    );
}

export default ParsedMessage
