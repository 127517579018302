import Input from "@/components/common/Input";
import RadioGroup from "@/components/common/RadioGroup";
import Select from "@/components/common/Select";
import UploadPhoto from "@/components/common/UploadPhoto";
import { statusOptions } from "@/constants/options";
import { SupplierSchemaRequest } from "@/validation/supplierSchema";
import { Controller, UseFormReturn } from "react-hook-form";

interface SupplierDetailsProps {
    formState: UseFormReturn<SupplierSchemaRequest>
}

const SupplierDetails = ({ formState }: SupplierDetailsProps) => {

    const { register, watch, setValue, control, formState: { errors, defaultValues } } = formState;

    const handleStatusChange = (value: string) => {
        setValue("status", value as SupplierSchemaRequest["status"]);
    };

    return (
        <div className="row g-20">
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Logo</span>
                    </div>
                    <div className="col-8">
                        <UploadPhoto
                            onChange={(file) => setValue("photo", file)}
                            value={watch("photo") as File | string}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Supplier Name</span>
                    </div>
                    <div className="col-8">
                        <Input
                            className="input--md"
                            {...register("supplierName")}
                            placeholder="Enter supplier name here..."
                            error={errors.supplierName?.message}
                        />
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Type</span>
                    </div>
                    <div className="col-8">
                        <Controller
                            name="type"
                            control={formState.control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={[
                                        { label: "Type", value: "Type" },
                                        { label: "Customer", value: "Customer" },
                                    ]}
                                    placeholder={'Select Type'}
                                    onChange={(option) => {
                                        field.onChange(option.value)
                                    }}
                                    value={field.value}
                                    error={errors.type?.message}
                                />
                            )}
                        >
                        </Controller>
                    </div>
                </div>
            </div>           
            <div className="col-12">
                <div className="row">
                    <div className="col-4 flex items-center">
                        <span className="fw-500 text--md">Status</span>
                    </div>
                    <div className="col-8">
                        <RadioGroup
                            options={statusOptions}
                            onChange={handleStatusChange}
                            value={watch("status")}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default SupplierDetails;
