import ChevronsIcon from "@/assets/img/Chevron-Down--Streamline-Lucide.svg?react";
import CheckIcon from "@/assets/img/icon-check-10.svg?react";
import Dropdown from "@/components/common/Dropdown";
import Input from "@/components/common/Input";
import clsx from "clsx";
import { useState } from "react";
import Spinner from "@/components/common/Spinner";
import { IDrodownOption } from "@/types";
import SettingsIcon from "@/assets/img/icon-settings.svg?react";

interface IOptionDropdownProps {
    defaultTitle?: string;
    options: IDrodownOption[];
    selectedOptionId: number | string;
    onChange: (id: number | string) => void;
}

const DocumentsDropdown = ({ options, selectedOptionId, onChange, defaultTitle }: IOptionDropdownProps) => {
    const [search, setSearch] = useState("");

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const getFilteredOptions = () => {return options.filter((option) => option.name.toLowerCase().includes(search.toLowerCase()))};

    return (
        <Dropdown
            dropdownClasses="w-full flex-auto max-w-full dropdown-sidebar m-0"
            bodyClassName="dropdown__body dropdown-sidebar__body dropdown__table max-w-600"
            buttonClassName="w-full"
            button={(
                <button type="button" className="dropdown-sidebar-btn dropdown-btn w-full" style={{ pointerEvents: 'all' }}>
                    <div className="dropdown-sidebar__group-selection truncate flex-auto">
                        <span className="ico">
                            <SettingsIcon />
                        </span>
                        <span className="info">{options.find((option) => option.id === selectedOptionId)?.name || defaultTitle}</span>
                        </div>
                        <div className="dropdown-sidebar__arrows">
                            <ChevronsIcon />
                        </div>
                </button>
            )}
        >
            {({ close }) => (
                <>
                    <div className="dropdown__search-item">
                        <Input
                            className="input--xs"
                            placeholder="Search Option..."
                            value={search}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="dropdown-block">
                        <div className="dropdown-block--body">
                            <ul className="dropdown-sidebar__selection-list">
                                {getFilteredOptions().map((option, index) => (
                                    <li
                                        key={index}
                                        className={clsx("dropdown-sidebar__selection-item truncate", { "checked-selection": option.id === selectedOptionId })}
                                        onClick={() => {
                                            onChange(option.id)
                                            close()
                                        }}
                                        style={{ cursor: "pointer", opacity: 1, pointerEvents: "auto" }}
                                    >
                                        <span className="ico" style={{ opacity: option.id === selectedOptionId ? 1 : 0.5 }}>
                                            <SettingsIcon />
                                        </span>
                                        <span className="info">{option.name}</span>
                                        {option.id === selectedOptionId &&
                                            <div className="dropdown-sidebar__selection-checked">
                                                <CheckIcon />
                                            </div>
                                        }
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </Dropdown>
    )
}

export default DocumentsDropdown
