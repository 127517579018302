import { getMe } from "@/redux/appSlice";
import { useAppSelector } from "@/redux/store";
import { User } from "@/types";


type UseSelectMe = () => User | null;

export const useSelectMe: UseSelectMe = () => {

    const user = useAppSelector(getMe);

    return user;

};
