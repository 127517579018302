import React from "react";
import Button from "@/components/common/Button";
import ChevronRightIcon from "@/assets/img/chevron-right-icon.svg?react";
import ChevronLeftIcon from "@/assets/img/chevron-left-icon.svg?react";

interface EntityFooterProps {
    isLoading?: boolean;
    handleCancel: () => void;
    handleGoBack: () => void;
    onSubmit?: () => Promise<void> | void;
    completeText?: string;
    nextStepText?: string;
    isFinalStep?: boolean;
    isFirstStep?: boolean;
    showBackButton?: boolean
}

const EntityFooter: React.FC<EntityFooterProps> = ({
    isLoading = false,
    handleCancel,
    handleGoBack,
    onSubmit,
    completeText = "Complete",
    nextStepText = "Next Step",
    isFinalStep,
    isFirstStep,
    showBackButton = true
}) => {
    return (
        <div className="modal__footer justify-between">
            {showBackButton && (
                <Button
                    uiType="custom-1"
                    loading={isLoading}
                    onClick={isFirstStep ? handleCancel : handleGoBack}
                >
                    {isFirstStep
                        ? "Cancel"
                        : <div className="flex items-center"><span className="ico"><ChevronLeftIcon /></span>Back</div>
                    }
                </Button>
            )}

            <Button
                className="ml-auto"
                uiType="primary-advance-1"
                loading={isLoading}
                type="submit"
                onClick={onSubmit}
            >
                <span>{isFinalStep ? completeText : nextStepText}</span>
                {!isFinalStep && (
                    <span className="ico">
                        <ChevronRightIcon />
                    </span>
                )}
            </Button>
        </div>
    );
};

export default EntityFooter;