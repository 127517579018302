import * as yup from 'yup';

export const addressSchema = yup.object().shape({
    country: yup.string().required(),
    state: yup.string().required(),
    city: yup.string().optional().nullable(),
    street: yup.string().required(),
    building: yup.string().required(),
    zip: yup.string().required(),
    unit: yup.string().optional(),
});
