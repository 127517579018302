import React, { useState } from 'react'
import CloudUploadIcon from "@/assets/img/ico-cloud-upload.svg?react";
import Button from './Button';

interface DragFileInputProps {
    isMultiple?: boolean;
    title?: string;
    externalHandler?: (files: File[]) => void;
    accepts?: string;
}

const DragFileInput: React.FC<DragFileInputProps> = ({
    isMultiple = true,
    title,
    externalHandler,
    accepts = '.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf'
}) => {
    const [files, setFiles] = useState<FileList | Array<undefined>>([]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setFiles(event.target.files);

        if (externalHandler) {
            externalHandler(Array.from(event.target.files));
        }
    }

    return (
        <div className='drag-input_container'>
            <div className='drag-input_label'>
                <div className='drag-input_text'>
                    <span className='drag-input_upload-icon'>
                        <CloudUploadIcon />
                    </span>

                    <span>{title || "Drag & Drop document(s) here or"}</span>
                </div>

                <Button
                    size='small'
                    uiType='primary-advance-1'
                >
                    <span className='ps-4 pe-4'>Upload</span>
                </Button>
            </div>

            <input
                type='file'
                id='drag'
                className='drag-input_input'
                multiple={isMultiple}
                onChange={handleChange}
                accept={accepts}

            />
        </div>
    )
}

export default DragFileInput
