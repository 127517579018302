import { useCreateAssistantMutation } from "@/api/aiApi";
import Modal, { ModalProps } from "./Modal";
import UploadCsv from "./NewEntityModal/UploadCsv";
import { Assistant } from "@/types";

interface NewAssistantModalProps extends ModalProps {
    onCreateSuccessful?: (data: Assistant) => void
}

const NewAssistantModal: React.FC<NewAssistantModalProps> = ({
    onCreateSuccessful, ...props
}) => {
    const [create, { isLoading }] = useCreateAssistantMutation()

    const handleSubmit = async (file: File) => {

        const response = await create({ file }).unwrap()
        if (onCreateSuccessful && response?.success && response?.data) {
            onCreateSuccessful(response.data)
        }
        props.onClose()
    }
    return (
        <Modal
            {...props}
            closeOnClickOutside
            contentClassName={"modal--rounded"}
        >
            <UploadCsv
                title="Upload File"
                showExampleFile={false}
                showBackButton={false}
                handleCompleteClick={handleSubmit}
                handleGoBack={props.onClose}
                handleCancel={props.onClose}
                loading={isLoading}
                accepts=".xlsx"
            />
        </Modal>
    );

};

export default NewAssistantModal;
