import { useCreateThreadMutation, useSendMessageToAssistantMutation } from "@/api/aiApi";
import useBreadcrumbs from "@/hooks/useBreadcrumbs";
import { useState } from "react";
import ChatBody from "./components/ChatBody";
import ChatFooter from "./components/ChatFooter";
import DocumentsDropdown from "./components/DocumentsDropdown";
import { AssistantMessage } from "@/types";
import Loader from "@/components/common/Loader";
import Button from "@/components/common/Button";
import useModal from "@/hooks/useModal";
import { ModalID } from "@/providers/ModalProvider";
import OptionDropdown from "./components/OptionDropdown";
import { ChatPriceType } from "@/types/enums";

const priceTypeOptions = Object.entries(ChatPriceType).map(([key, value]) => ({ id: value, name: key }))

const ChatPage = () => {
    useBreadcrumbs([{
        path: '/',
        name: 'Chat'
    }]);

    const [selectedAssistantId, setSelectedAssistantId] = useState(null);
    const [selectedPriceType, setSelectedPriceType] = useState(ChatPriceType.Sell);
    const [isShowDebugData, setIsShowDebugData] = useState(false);

    const [messages, setMessages] = useState([])
    const [createThread, { isLoading: isCreatingThread, data: threadResponse }] = useCreateThreadMutation({})
    const [sendMessage, { isLoading: isMessageSending }] = useSendMessageToAssistantMutation({})
    const { openModal } = useModal()

    const handleSendMessage = async (message: string) => {
        const newMessage: AssistantMessage = {
            content: message,
            createdAt: new Date().toISOString(),
            id: messages.length + 1,
            sender: "user",
            updatedAt: new Date().toISOString(),
            userId: 1,
            aiThreadId: 1,
        }
        setMessages(prev => prev.concat(newMessage))
        const response = await sendMessage(
            { 
                threadId: threadResponse.data.id, 
                body: { 
                    content: message, 
                    price_type: selectedPriceType,
                    show_debug_data: isShowDebugData, 
                } 
            }).unwrap()
        const answer = response.data
        setMessages(prev => prev.concat(answer))
    }


    const handleAssistantChange = async (id: number) => {
        setSelectedAssistantId(id);
        setMessages([]);
        createThread({ threadId: id, body:   { name: 'Test'}});
    }

    const handlePriceTypeChange = (id: string) => {
        setSelectedPriceType(id);
    };

    const handleShowDebugChange = () => {
        setIsShowDebugData(state => !state);
    }

    const handleCreateAssistantModalOpen = () => {
        openModal(ModalID.NewAssistant, {
            onCreateSuccessful: (data) => handleAssistantChange(data.id)
        })
    }

    return (
        <div className="content ovh">
            <div className="container container-full px-0 h-full flex flex-col">
                <div className="block-container">
                    <div className="heading--row">
                        <div className="flex items-center gap--md">
                            <div className="heading-option" style={{ width: 220 }}>
                                <DocumentsDropdown selectedAssistantId={selectedAssistantId} onChange={handleAssistantChange} />
                            </div>
                            <div className="heading-option" style={{ width: 220 }}>
                                <OptionDropdown 
                                    options={priceTypeOptions} 
                                    selectedOptionId={selectedPriceType} 
                                    onChange={handlePriceTypeChange}
                                    defaultTitle="Price Type"
                                />
                            </div>
                            <div className="heading-option">
                                <label
                                    className="check-wrapper table-gap-x"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <span className="info">Show debug data</span>&nbsp;
                                    <input
                                        type="checkbox"
                                        hidden
                                        checked={isShowDebugData}
                                        onChange={handleShowDebugChange}
                                    />
                                    <span className="check check--xs check-custom-2"></span>
                                </label>
                            </div>
                        </div>
                        
                        <div className="heading-option">
                            <Button onClick={handleCreateAssistantModalOpen} className="btn--custom-1">Create assistant</Button>
                        </div>
                    </div>
                </div>
                <div className="chat">
                    {isCreatingThread ? (
                        <div className="chat-body items-center justify-center">
                            <Loader size={42} />
                        </div>
                    ) : (
                        <ChatBody messages={messages} loading={isMessageSending} />
                    )}
                    <ChatFooter onSendMessage={handleSendMessage} loading={isMessageSending} />
                </div>
            </div>
        </div>
    )
}


export default ChatPage