import Textarea from '@/components/common/Textarea'

import SendIcon from '@/assets/img/arrow-up.svg?react'
import Button from '@/components/common/Button'
import { useState } from 'react'

interface ChatFooterProps {
    onSendMessage: (message: string) => void
    loading?: boolean
}
const ChatFooter = ({ onSendMessage, loading }: ChatFooterProps) => {
    const [message, setMessage] = useState('')
    const handleSendMessage = () => {
        if (loading) return
        onSendMessage(message)
        setMessage('')
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            handleSendMessage()
        }
        if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault()
            const { selectionStart, value } = e.currentTarget
            setMessage(value.slice(0, selectionStart) + '\n' + value.slice(selectionStart))
        }
    }
    return (
        <div className='chat-footer'>
            <div className='form-group input--md'>
                <textarea
                    className='chat-footer__input textarea'
                    placeholder="Type a message..."
                    style={{ resize: 'none' }}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    onKeyDown={handleKeyDown}
                />
            </div>
            <div className='chat-footer__actions'>
                <Button loading={loading} onClick={handleSendMessage} className='btn btn--custom-1 btn--square btn--xs chat-footer__btn-send'>
                    <span className='ico'>
                        <SendIcon />
                    </span>
                </Button>
            </div>
        </div>
    )
}

export default ChatFooter
