import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import ScollBottomIcon from '@/assets/img/ico-chevron-down.svg?react';
import { AssistantMessage } from '@/types';
import Loader from '@/components/common/Loader';
import ParsedMessage from './ParsedMessage';
import { markDownExapmle } from '@/utils';
interface ChatBodyProps {
    messages: AssistantMessage[]
    loading?: boolean
}

const ChatBody = ({ messages, loading }: ChatBodyProps) => {
    const ref = useRef<HTMLDivElement>(null)
    const [isScrollBtnVisible, setIsScrollBtnVisible] = useState(false)

    const scrollToBottom = () => {
        if (ref.current) {
            ref.current.scrollTo({
                top: ref.current.scrollHeight,
                behavior: 'smooth',
            })
        }
    }

    const handleScroll = () => {
        if (!ref.current) return;
        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        setIsScrollBtnVisible(scrollHeight - (scrollTop + clientHeight) > 100);
    };

    useEffect(() => {
        const chatRef = ref.current;
        if (!chatRef) return;
        chatRef.addEventListener('scroll', handleScroll);
        return () => chatRef.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (!ref.current) return
        scrollToBottom()
    }, [messages])


    useLayoutEffect(() => {
        if (!ref.current) return
        ref.current.scrollTop = ref.current.scrollHeight + 500
    }, [])



    return (
        <div ref={ref} className="chat-body">
            {messages?.length > 0 ? messages.map((message) => (
                <div key={message.id} className={`chat-message ${message.sender !== 'bot' ? 'chat-message--mine' : ''}`}>
                    <div className="chat-message__content">
                        <label className='chat-message__sender'>{message.sender === 'bot' ? 'KBF AI' : 'User'}:</label>
                        {/* <p>{message.content}</p> */}
                        <ParsedMessage content={message.content} />
                    </div>
                </div>
            )) : (
                <div className="text-center mt-30">You can ask anything about the projects</div>
            )}
            {loading && (
                <div className="text-center mt-auto">
                    <Loader size={24} />
                </div>
            )}
            {isScrollBtnVisible && (
                <button className="btn btn--custom-1 btn--square btn--xs rounded-full chat-body__scroll-to-bottom" onClick={scrollToBottom}>
                    <ScollBottomIcon />
                </button>
            )}
        </div>
    )
}

export default ChatBody
