import { DOMAIN } from "@/constants";
import { localStorageService } from "@/services/localStorageService";
import { LocalStorageEntities } from "@/types/enums";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
    baseUrl: DOMAIN,
    prepareHeaders: (headers) => {

        const token = localStorageService.get(LocalStorageEntities.AuthToken);
        headers.set("Authorization", `Bearer ${token}`);
        return headers;

    }
});

export const baseApi = createApi({
    reducerPath: "baseApi",
    baseQuery,
    tagTypes: ["me", "users", 'clients', 'professions', 'teams', 'projects', 'assistants'],
    endpoints: (builder) => ({})
});
