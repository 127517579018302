import type { Assistant, AssistantMessage, ResponseType } from "../types";
import { baseApi } from "./baseApi";
import { objectToFormData } from "@/utils";


const base = "api/ai";

export const aiApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getAssistants: builder.query<
            ResponseType<Assistant[]>,
            void
        >({
            query: () => `${base}/assistants`,
            providesTags: ["assistants"]
        }),
        createThread: builder.mutation<
            ResponseType<{ id: number }>,
            { threadId: number, body: { name: string } }
        >({
            query: ({ threadId, body }) => ({
                url: `${base}/threads/${threadId}`,
                method: "POST",
                body
            }),
        }),
        sendMessageToAssistant: builder.mutation<
            ResponseType<AssistantMessage>,
            { threadId: number, body: { content: string, 
                price_type: string,
                show_debug_data: boolean, } }
        >({
            query: ({ threadId, body }) => ({
                url: `${base}/threads/${threadId}/messages/`,
                method: "POST",
                body
            }),
        }),
        createAssistant: builder.mutation<
            ResponseType<Assistant>,
            { file: File }
        >({
            query: (body) => ({
                url: `${base}/assistants`,
                method: "POST",
                body: objectToFormData(body)
            }),
            invalidatesTags: ["assistants"]
        })
    })
});

export const {
    useGetAssistantsQuery,
    useCreateThreadMutation,
    useSendMessageToAssistantMutation,
    useCreateAssistantMutation
} = aiApi;
