import AssistantModal from "@/components/modals/AssistantModal";
import TaskModal from "@/components/modals/TaskModal";
import useModal from "@/hooks/useModal";
import EditClientModal from "@/pages/ClientsPage/EditClientModal";
import NewClientModal from "@/pages/ClientsPage/NewClientModal";
import NewProjectModal from "@/pages/ProjectsPage/NewProjectModal";
import NewMaterialModal from "@/pages/SuppliersInventoryPage/NewMaterialModal";
import NewOrderModal from "@/pages/SuppliersInventoryPage/NewOrderModal";
import NewSupplierModal from "@/pages/SuppliersInventoryPage/NewSuppliersmodal";
import EditTeamModal from "@/pages/TeamManagementPage/EditTeamModal";
import NewTeamModal from "@/pages/TeamManagementPage/NewTeamModal";
import EditUserModal from "@/pages/UsersPage/EditUserModal";
import NewUserModal from "@/pages/UsersPage/NewUserModal";
import { useAppSelector } from "@/redux/store";
import { NewEventModal } from "@/pages/SchedulingPage/NewEventModal";
import EditProjectModal from "@/pages/ProjectsPage/EditProjectModal";
import NewAssistantModal from "@/components/modals/NewAssistantModal";

export enum ModalID {
    Assistent = "assistent",
    Task = "task",
    NewClient = "newClient",
    NewEvent = "newEvent",
    NewMaterial = "newMaterial",
    NewOrder = "newOrder",
    NewProject = "newProject",
    NewSupplier = "newSupplier",
    NewTeam = "newTeam",
    NewUser = "newUser",
    EditClient = "editClient",
    EditProject = "editProject",
    EditTeam = "editTeam",
    EditUser = "editUser",
    NewAssistant = "newAssistant",
}

export interface ModalProps extends Record<ModalID, any> {
    task: Partial<React.ComponentProps<typeof TaskModal>>;
    assistent: Partial<React.ComponentProps<typeof AssistantModal>>;
    newClient: Partial<React.ComponentProps<typeof NewClientModal>>;
    newUser: Partial<React.ComponentProps<typeof NewUserModal>>;
    newProject: Partial<React.ComponentProps<typeof NewProjectModal>>;
    editProject: Partial<React.ComponentProps<typeof EditProjectModal>>;
    editClient: Partial<React.ComponentProps<typeof EditClientModal>>;
    newTeam: Partial<React.ComponentProps<typeof NewTeamModal>>;
    newMaterial: Partial<React.ComponentProps<typeof NewMaterialModal>>;
    newOrder: Partial<React.ComponentProps<typeof NewOrderModal>>;
    newSupplier: Partial<React.ComponentProps<typeof NewSupplierModal>>;
    newEvent: Partial<React.ComponentProps<typeof NewEventModal>>;
    newAssistant: Partial<React.ComponentProps<typeof NewAssistantModal>>;
}

export const modalComponents: Record<ModalID, React.ComponentType<any>> = {
    task: TaskModal,
    assistent: AssistantModal,
    newClient: NewClientModal,
    newUser: NewUserModal,
    newProject: NewProjectModal,
    editClient: EditClientModal,
    editUser: EditUserModal,
    newTeam: NewTeamModal,
    editTeam: EditTeamModal,
    newMaterial: NewMaterialModal,
    newOrder: NewOrderModal,
    newSupplier: NewSupplierModal,
    newEvent: NewEventModal,
    editProject: EditProjectModal,
    newAssistant: NewAssistantModal
};

const ModalProvider = () => {

    const modal = useAppSelector((state) => state.app.modal);
    const { closeModal } = useModal();

    if (!modal) return null;

    const ModalComponent = modalComponents[modal.id];

    return <ModalComponent {...modal.props} isOpen onClose={closeModal} />;

};

export default ModalProvider;
