import exp from "constants";

export enum UserRole {
  ADMIN = "admin",
  USER = "user"
}

export enum ProjectStatus {
  AGREEMENT = "agreement",
  PROPOSAL = "proposal",
  DEVELOPMENT = "development",
  EXPEDITION = "expedition",
  ROUGH_IN = "rough-in",
  FINISHES = "finishes",
  PUNCH_OUT = "punch-out",
  COMPLETED = "completed",
}

export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive",
}
export enum MaterialStatus {
  InStock = "In Stock",
  Pending = "Pending",
  OutOfStock = "Out of Stock",
}
export enum MaterialOrderStatus {
  Delivered = "Delivered",
  InProgress = "In Progress",
  OutOfStock = "Out of Stock",
}

export enum ClientType {
  RESIDENTAL = 'residential'
}

export enum RiskLevel {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high"
}

export enum ContactMethod {
    EMAIL = "email",
    PHONE = "phone",
    SMS = "sms"
}
export enum FrequencyEnum {
    ONCE = "once",
    REPEAT = "repeat",
}
export enum LocationEnum {
    PHYSICAL = "physical",
    VIRTUAL = "virtual",
}

export enum EntityCreationStages {
    SelectOption = "selectOption",
    FillData = "fillData",
    Complete = "complete",
    UploadCsv = 'uploadCsv'
}

export enum LocalStorageEntities {
    AuthToken = 'authToken'
}

export enum CsvDataTypes {
    User = 'user',
    Client = 'client',
    Team = 'team',
    Project = 'project'
}

export enum InsuranceOptionsText {
    Yes = 'Yes',
    No = 'No',
}

export enum ProjectTypes {
    ResidentialRemodel = "Residential Remodel",
    CommercialRemodel = "Commercial Remodel",
    NewConstruction = "New Construction",
    InteriorDesign = "Interior Design",
    LandscapeDesign = "Landscape Design",
    KitchenRemodel = "Kitchen Remodel",
    BathroomRemodel = "Bathroom Remodel",
    OfficeSpace = "Office Space",
    RetailSpace = "Retail Space",
    HospitalityDesign = "Hospitality Design",
    UrbanPlanning = "Urban Planning",
    MixedUse = "Mixed Use"
}

export enum ChatOptions {
    PriceType = 'priceType',
    ShowDebugData = 'showDebugData',
}

export enum ChatPriceType {
    Sell = 'SELL',
    Budget = 'BUDGET',
}