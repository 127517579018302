import { baseApi } from "@/api/baseApi";
import { toast } from "react-toastify";

export const toastMiddleware = () => (next: any) => (action: any) => {

    if (baseApi.util.resetApiState.match(action)) return next(action);
    const message = action?.payload?.message;
    const isSuccess = action?.payload?.success
    if (action.type.endsWith("/fulfilled") && action.meta.arg.endpointName) {

        const { endpointName, type } = action.meta.arg;

        if (type === "mutation") {
            console.log(endpointName, type, 'sas');
            if(['createThread', 'sendMessageToAssistant'].includes(endpointName)) return next(action);
            toast(message || `${endpointName} completed successfully!`, { type: isSuccess ? 'success' : 'error' });

        }

    } else if (action.type.endsWith("/rejected") && action.meta.arg.endpointName) {

        const { endpointName } = action.meta.arg;

        toast.error(message || `Error occurred during ${endpointName}. Please try again.`);

    }

    return next(action);

};
