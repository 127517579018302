import i18next from "i18next";
import { Address, IAddressFormData } from "@/types";
import { DOMAIN } from "../constants";
import placeholderImage from "@/assets/img/placeholder.png";

export const getFullName = (user: { firstName: string, lastName: string, name?: string }) => [user.firstName, user.lastName, user?.name].filter(Boolean).join(" ");

export const getFullUrl = (paths: (string | number)[]): string => {

    return paths.map((path) => path.toString()).join("/");

};

export const toCurrency = (value: number, currency: string = "USD"): string => {

    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
        trailingZeroDisplay: "stripIfInteger"
    });
    return formatter.format(value);

};

export const getMediaUrl = (
    path: string | null | undefined,
    placeholder?: string
): string => {
    if (path?.startsWith('http')) {
        return path;
    }

    const baseUrl = DOMAIN;

    if (!path) {

        return placeholder || placeholderImage;

    }

    return `${baseUrl}${path}`;

};


export const objectToQueryString = (params?: Record<string, any> | void): string => {

    if (!params) return "";
    return Object.entries(params).
        filter(([_, value]) => value !== undefined && value !== null).
        map(([key, value]) => (Array.isArray(value)
            ? `${key}=${value.join(",")}`
            : `${key}=${encodeURIComponent(value)}`)
        ).
        join("&");

};

export const objectToFormData = (
    obj: Record<string, any>,
    formData: FormData = new FormData(),
    parentKey?: string
): FormData => {

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];
            const formKey = parentKey
                ? `${parentKey}[${key}]`
                : key;

            if (value === undefined || value === null) continue;

            if (value instanceof Date) {
                formData.append(formKey, value.toISOString());
            } else if (value instanceof File) {
                formData.append(formKey, value);
            } else if (Array.isArray(value)) {
                if (['contracts', 'photos'].includes(formKey)) {
                    value.forEach((item, index) =>
                        objectToFormData({ [`${formKey}`]: item }, formData)
                    );
                } else {
                    value.forEach((item, index) =>
                        objectToFormData({ [`${formKey}[${index}]`]: item }, formData)
                    );
                }
            } else if (typeof value === "object" && value !== null) {
                objectToFormData(value, formData, formKey);
            } else {
                formData.append(formKey, typeof value === "boolean" ? String(value) : value);
            }
        }
    }

    return formData;
};

export function formatAddress(address: IAddressFormData, fields?: (keyof IAddressFormData)[]): string {
    const defaultFields: (keyof IAddressFormData)[] = [
        "street",
        "building",
        "city",
        "state",
        "zip",
        "country",
        "unit"
    ]
    const fieldsToFormat = fields ?? defaultFields;

    return fieldsToFormat
        .map(field => address[field])
        .filter(Boolean)
        .join(", ");
}
export function capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const normalizePhone = (value: string): string => value ? value.replace(/\D/g, "") : '';

export const getPhoneWithPlusSymbol = (phone: string | undefined): string => phone?.startsWith('+') ? phone : `+${phone}`;

export const getErrorText = (key: string) => i18next.t(`errors.validation.${key}`);

export const checkAreNamesEqual = (firstName: string, secondName?: string): boolean => {
    if (!secondName) {
        return false;
    }

    return firstName.trim().toLocaleLowerCase() === secondName.trim().toLocaleLowerCase();
}

export const checkAreNamesSimilar = (firstName: string, secondName?: string): boolean => {
    if (!secondName) {
        return false;
    }

    return firstName.trim().toLocaleLowerCase().includes(secondName.trim().toLocaleLowerCase());
}

export const checkIsNameSimilarToUserName = (
    userFirstName: string, userLastName: string, nameToCompare?: string): boolean => {
    if (!nameToCompare) {
        return false;
    }

    const userFullName = getFullName({firstName: userFirstName, lastName: userLastName});

    return checkAreNamesSimilar(userFullName, nameToCompare);
}

export const checkAreTextsEqual = (firstText: string, secondText?: string): boolean => {
    if (!secondText) {
        return false;
    }

    return firstText.trim().toLowerCase() === secondText.trim().toLowerCase();
}

export const markDownExapmle = `# Heading 1
## Heading 2
### Heading 3
#### Heading 4
##### Heading 5
###### Heading 6

---

**This text is bold**, and _this text is italic_.

***This one is both bold and italic!***

~~This text is strikethrough.~~

---

## Lists

### Numbered list:
1. First item
2. Second item
3. Third item

### Bulleted list:
- Item one
- Item two
  - Sub-item two.a
  - Sub-item two.b

### Checkbox list:
- [x] Task 1
- [ ] Task 2
- [ ] Task 3

---

## Links

[This is a link to Google](https://www.google.com)

---

## Images

![Kitten](https://placekitten.com/200/300)

---

## Code

### Inline code:
'console.log('Hello, world!');'

### Code block:
'python
print("Hello, world!")
'

---

## Blockquotes

> This is a level 1 quote
>> This is a nested level 2 quote

---

## Table

| Name  | Age | City      |
|-------|-----|-----------|
| Anna  | 24  | Kyiv      |
| Peter | 30  | Lviv      |
| Olga  | 27  | Odesa     |

---

## HTML in Markdown

<b>This text is bold using HTML</b>  
<i>This text is italic</i>  
<u>This text is underlined</u>
`