import ChevronsIcon from "@/assets/img/Chevron-Down--Streamline-Lucide.svg?react";
import DocumentsIcon from "@/assets/img/icon-projects.svg?react";
import DocumentIcon from "@/assets/img/document.svg?react";
import CheckIcon from "@/assets/img/icon-check-10.svg?react";
import Dropdown from "@/components/common/Dropdown";
import Input from "@/components/common/Input";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useGetAssistantsQuery } from "@/api/aiApi";
import Spinner from "@/components/common/Spinner";

interface DocumentsDropdownProps {
    selectedAssistantId: number;
    onChange: (id: number) => void;
}

const DocumentsDropdown = ({ selectedAssistantId, onChange }: DocumentsDropdownProps) => {
    const [search, setSearch] = useState("");
    const { data: assistantsResponse, isError, isLoading } = useGetAssistantsQuery()
    const assistants = assistantsResponse?.data || []
    const filteredDocuments = assistants.filter((doc) =>
        doc.name.toLowerCase().includes(search.toLowerCase())
    );
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    useEffect(() => {
        if (!selectedAssistantId && assistants.length > 0) {
            onChange(assistants[0].id)
        }
    }, [assistants])

    return (
        <Dropdown
            dropdownClasses="w-full flex-auto max-w-full dropdown-sidebar m-0"
            bodyClassName="dropdown__body dropdown-sidebar__body dropdown__table max-w-600"
            buttonClassName="w-full"
            button={(
                <button type="button" className="dropdown-sidebar-btn dropdown-btn w-full" style={{ pointerEvents: 'all' }}>
                    <div className="dropdown-sidebar__group-selection truncate flex-auto">
                        {isLoading
                            ? (
                                <span className="mx-auto ico">
                                    <Spinner />
                                </span>
                            )
                            : (
                                <>
                                    <span className="ico">
                                        <DocumentIcon />
                                    </span>
                                    <span className="info">{assistants.find((doc) => doc.id === selectedAssistantId)?.name}</span>
                                </>
                            )}
                    </div>
                    <div className="dropdown-sidebar__arrows">
                        <ChevronsIcon />
                    </div>
                </button>
            )}
        >
            {({ close }) => (
                <>
                    <div className="dropdown__search-item">
                        <Input
                            className="input--xs"
                            placeholder="Search Document..."
                            value={search}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className="dropdown-block">
                        <div className="dropdown-block--body">
                            {isLoading
                                ? <Spinner />
                                : (
                                    <ul className="dropdown-sidebar__selection-list">
                                        {filteredDocuments.map((doc, index) => (
                                            <li
                                                key={index}
                                                className={clsx("dropdown-sidebar__selection-item truncate", { "checked-selection": doc.id === selectedAssistantId })}
                                                onClick={() => {
                                                    onChange(doc.id)
                                                    close()
                                                }}
                                                style={{ cursor: "pointer", opacity: isLoading || isError ? 0.5 : 1, pointerEvents: isLoading || isError ? "none" : "auto" }}
                                            >
                                                <span className="ico" style={{ opacity: doc.id === selectedAssistantId ? 1 : 0.5 }}>
                                                    {doc.name === 'All files' ? <DocumentsIcon /> : <DocumentIcon />}
                                                </span>
                                                <span className="info">{doc.name}</span>
                                                {doc.id === selectedAssistantId &&
                                                    <div className="dropdown-sidebar__selection-checked">
                                                        <CheckIcon />
                                                    </div>
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                )}
                        </div>
                    </div>
                </>
            )}
        </Dropdown>
    )
}

export default DocumentsDropdown
