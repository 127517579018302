import i18next from 'i18next';
import { DOMAIN } from '@/constants';

export async function loadAllTranslations() {
    try {
        const response = await fetch(
        `${DOMAIN}/translations`
        );
        const data = await response.json();
        if (data.success) {
        const translations = data.data;
        Object.keys(translations).forEach((lng) => {
            if (lng !== i18next.language) {
            i18next.addResourceBundle(
                lng,
                'translation',
                translations[lng].translation,
                true,
                true
            );
            }
        });
        } else {
        console.error('Failed to load all translations');
        }
    } catch (error) {
        console.error('Error loading translations:', error);
    }
}

export function initializeLanguages() {
    i18next.on('initialized', () => {
    loadAllTranslations();
    });
}
